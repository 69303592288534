module.exports = {
  language: {
    name: 'English',
    short: 'zh_CN'
  },
  logoName: '供应商',
  asin: '输入asin',
  selectAnchor: '选择主播',
  selectBrand: '选择品牌',
  chinaTime: '中国时间',
  localTime: '当地时间',
  localData: '当地日期',
  typeLive: '直播中',
  endLive: '已结束',
  notStartLive: '未开始',
  watchLive: '观看',
  selectShop: '选择店铺',
  selectMonth: '选择月份',
  brokerage: '佣金',
  affirm: '确认',
  confirmed: '已确认',
  particulars: '明细',
  account: '账户可用余额（美元）',
  popconfirmMessage: '确认，即表示认可结算金额，不可再做更改，现在确认么？',
  languMsg: '请选择语言',
  beginTime: '开始时间',
  endTime: '结束时间',
  calendar: {
    numLives: '本月已创建10场直播',
    lived: '已直播10场',
    session: '总场次10场',
    sessioning: '已播场次10场',
    shopNums: '商品数10件'
  },
  setting: {
    crmSetting: '系统设置',
    password: '密码',
    languageType: '首选语言',
    updatePassword: '修改密码',
    save: '保存',
    quitLogin: '退出登录',
    quitMsg: '请确认是否要退出当前登录',
    platform: '选择系统',
    amazon: '亚马逊商家',
    wahool: '服饰供应商',
    enter: '进入',
    serviceSystem: '服务系统',
    serviceSystem1: '营销服务系统',
    serviceSystem2: '分销服务系统'
  },
  paswordSet: {
    oldInputMsg: '请输入新密码',
    newInputMsg: '请再次输入新密码',
    verifyOldMsg: '请输入密码',
    verifyNewMsg: '请输入确认密码',
    oldNewOvifyMsg: '两次密码不一致'
  },
  login: {
    usernameLabel: '用户名',
    passwordLabel: '密码',
    userNamePla: `Test{'@'}****公司`,
    userNamePlas: '请输入您的账号',
    passwordPla: '请输入您的密码',
    login: '登录',
    amazonLogin: '登录并授权',
    amazonLoginTitle: '授权您的 Amazon Store',
    selectStoreTitle: '选择您要授权的店铺',
    selectedText: '已选',
    selectedNum: '个店铺',
    modalCancel: '取消',
    modalConfirm: '确认'
  },
  tablement: {
    month: '月份',
    settlement: '结算周期',
    mentType: '结算类别',
    shopName: '店铺',
    sellNums: '销售件数',
    sellPrice: '销售金额',
    returnNums: '退货件数',
    returnPrice: '退货金额',
    settlementPrice: '结算金额',
    operate: '操作'
  },
  detTab1: {
    merchant: '商家',
    shop: '店铺',
    localData: '当地日期',
    localTime: '当地时间',
    livelink: '直播链接',
    pit: '坑位',
    pitPrice: '坑位费',
    brand: '品牌/ASIN',
    clickrate: '点击率'
  },
  chargeTable: {
    enterPrice: '入账日期',
    accountType: '结算类别',
    accountCycle: '结算周期',
    remitPrice: '打款金额($)',
    entryPrice: '入帐金额($)',
    expendPrice: '支出金额($)',
    subtotalPrice: '小计($)'
  },
  detTab2: {
    month: '月份',
    dataTime: '日期',
    shop: '店铺',
    brand: '品牌',
    commissionType: '佣金%',
    commission: '佣金'
  },
  search: '查询',
  reset: '重置',
  view: '查看',
  edit: '编辑',
  confirm: '确定',
  cancel: '取消',
  download: '导出',
  overdue: '超期',
  submit: '提交',
  noData: '暂无数据',
  opSuccess: '操作成功～',
  addressInfo: {
    receiver: '收件人',
    phone: '联系方式',
    postal: '邮编',
    address: '收货地址'
  },
  components: {
    Pagination: {
      total: '共',
      page: '页',
      datas: '条数据'
    },
    AddExpressModal: {
      title: '添加快递',
      expressName: '快递公司',
      expressNameValidatorText: '请填写快递公司名称',
      expressCode: '快递单号',
      expressCodeValidatorText: '请输入快递单号',
      remark: '备注信息',
      remarkValidatorText: '请填写备注信息'
    },
    OperationLogModal: {
      title: '操作日志'
    }
  },
  orderManage: {
    filterForm: {
      detailSearchContent: '订单号/名称/编码'
    },
    tabsPane: {
      1: '待接订单',
      2: '待发订单',
      3: '完成订单',
      4: '取消订单'
    },
    paymentTypes: {
      1: '现结',
      2: '账期',
      3: '寄售'
    },
    typeList: {
      1: '待接',
      2: '异议',
      3: '确议'
    },
    table: {
      productPicList: '商品',
      orderSn: '订单号',
      paymentType: '结算方式',
      dissentReceiptAt: '交货时间',
      cancelAt: '取消时间',
      quantity: '商品数量',
      cancelQuantity: '取消数量',
      deliveryQuantity: '已发数量',
      dissentAmount: '实付金额',
      amount: '订单金额',
      expressFees: '运费',
      shippingCount: '发货批次',
      finishAt: '完成时间',
      status: '状态',
      cancelReason: '取消原因',
      operate: '操作',
      receive: '查看',
      delivery: '备货',
      log: '日志',
      overdue: '超期',
      day: '天',
      shippingCountTips1: '已发',
      shippingCountTips2: '批',
      receiptQuantity: '到货数量',
      typeValue: '采购类型',
      remark: '备注',
      operateTime: '操作时间'
    }
  },
  orderManageDetail: {
    tips: '温馨提示：接单前，请确认采购价和交货日期，避免造成不必要的影响。',
    purchaseQuantity: '采购数量（件）',
    purchaseAmount: '采购金额（¥）',
    expressFees: '运费（¥）',
    expressFeesTips: '编辑运费',
    ReceiveOrderTable: {
      receiveOrder: '接单',
      raiseObjection: '提交异议',
      objectionSubmit: '异议提交',
      objectionSubmitTips: '是否提交当前异议明细',
      download: '导出订单',
      downloadIframe: '下载',
      viewProcessSheet: '查看工艺单',
      pleaseConfirmCarefully: '请您仔细确认',
      importantInformation: '等重要信息',
      informationType: '商品、价格、交期、工艺单',
      table: {
        productPicTemplate: '图片',
        productName: '商品名称',
        sku: 'SKU',
        productParams: '变体信息',
        manufacturingNo: '厂家编号',
        initPurchasePrice: '商品单价(￥)',
        initQuantity: '商品数量',
        unitPrice: '单价',
        bargain: '议价',
        demand: '需求',
        canSent: '可发',
        dissentPriceCheckboxText: '同款全议',
        dissentQuantityCheckboxText: '同款全量',
        pleaseInput: '请输入'
      },
      dissentReceiptAt: '交货时间',
      dissentReceiptAtValidatorText: '请选择交货时间',
      confirmReceiveOrder: '确认接单'
    },
    DeliveryTable: {
      tabsPane: {
        1: '未发货',
        2: '已发货'
      },
      shippingStatus: {
        1: '待发货',
        2: '已发货',
        3: '部分收货',
        4: '已完成',
        5: '取消发货'
      },
      expressStatus: {
        1: '未签收',
        2: '已签收'
      },
      addressNum: '请填写快递单号',
      expressAddLabel: '添加快递',
      shippingStatusLabel: '收货状态',
      completion: '备货完成',
      printTheStockList: '打印备货单',
      oneClickDelivery: '快捷发货',
      createsAt: '下单',
      accepAt: '接单',
      import: '导入发货明细',
      importTips1: '上传格式不正确，请上传xls或者xlsx格式',
      importTips2: '表格无数据',
      download: '导出待发货明细',
      table: {
        cancelQuantity: '取消数量',
        quantityIncomGoods: '到货数量',
        productPicTemplate: '图片',
        productName: '商品信息',
        shippedQuantity: '待发货数量',
        sku: 'SKU',
        productParams: '变体信息',
        manufacturingNo: '厂家编号',
        dissentQuantity: '商品数量',
        deliveryQuantity: '已发数量',
        verifiedQuantity: '核实数量',
        printTimes: '打印时间',
        dissentQuantityTemp: '发货商品',
        receiptQuantity: '收货数量',
        unqualifiedQuantity: '质检数量',
        overdue: '超期',
        dissentQuantityTips1: '本次发货数量最大为',
        dissentQuantityTips2: '本次发货数量不能小于0',
        unit: '件',
        unReceive: '未收',
        receive: '已收',
        qualified: '合格',
        unqualified: '不合格'
      }
    }
  },
  deliveryManage: {
    filterForm: {
      shippingSn: '名称/编码',
      expressCode: '快递单号',
      orderTitle: '发货单号、订单号'
    },
    tabsPane: {
      1: '待发货',
      2: '已发货',
      3: '已完成'
    },
    table: {
      productPicList: '商品',
      shippingSn: '发货号',
      receivingWarehouseName: '接收仓库',
      deliveryQuantity: '发货数量',
      createAt: '创建时间',
      deliveryTime: '发货时间',
      deliveryData: '交货日期',
      createName: '发货人',
      expressListBos: '物流信息',
      operate: '操作',
      delivery: '查看备货',
      print: '打印发货单',
      send: '已发出',
      receive: '已收到',
      unqualified: '不合格'
    }
  },
  deliveryManageDetail: {
    filterForm: {
      nameCode: '名称/编码'
    },
    expressStatus: {
      1: '未签收',
      2: '已签收'
    },
    delivery: '确认发货',
    deliveryTips: '是否确认发货',
    print: '打印发货单',
    printStockList: '打印备货单',
    download: '导出发货明细',
    addExpress: '添加快递',
    table: {
      deliveryTime: '发货时间',
      receiptTime: '到货时间',
      purchaseOrderSn: '订单号',
      productPicTemplate: '图片',
      productName: '商品名称',
      sku: 'SKU',
      productParams: '变体信息',
      manufacturingNo: '厂家编号',
      deliveryQuantity: '发货数量',
      receiptQuantity: '收货数量',
      quantityToShipped: '待发数量',
      qualifiedQuantity: '质检数量',
      unReceive: '未收',
      receive: '已收',
      remark: '备注',
      qualified: '合格',
      unqualified: '不合格'
    },
    deliveryList: '发货清单',
    stockList: '备货清单',
    printTime: '打印时间',
    purchaseAmount: '采购单号'
  },
  returnManage: {
    filterForm: {
      name: '名称/编码/SKU',
      expressCode: '快递单号',
      returnName: '退货人',
      returnNumber: '退供单号'
    },
    tabsPane: {
      1: '待确认',
      2: '待寄回',
      3: '待签收',
      4: '待付款',
      5: '已完成',
      6: '已取消'
    },
    table: {
      returnNumber: '退供单号',
      typeDesc: '类型',
      waitHour: '等待时长',
      confirmAt: '确认时间',
      returnGoodsQuantity: '退货数量',
      lastReturnAmount: '退款金额（¥）',
      expressFees: '运费（¥）',
      waitPayAmount: '待退金额（¥）',
      processingStateDesc: '状态',
      createAt: '创建时间',
      logisticsInfor: '物流信息',
      // returnName: '退货人',
      cancelUid: '取消人',
      remark: '备注',
      createAndOutbound: '创建/出库时间',
      createAndFinish: '创建/完成时间',
      createAndCancel: '创建/取消时间',
      returnReason: '申请类型',
      returnReasonTypeName: '退供原因',
      operate: '操作',
      agreeSendBack: '确认',
      rejectSendBack: '拒绝',
      log: '日志记录',
      confirmReceipt: '确认签收'
    }
  },
  returnManageDetail: {
    pageTitle: '退货明细详情',
    navMessage: {
      returnType: '退货类型',
      returnQuantity: '退供数量',
      refundableAmount: '应退金额',
      freightAmount: '运费金额',
      amountRefunded: '已退金额',
      amountPayable: '待付金额',
      recipients: '收件人',
      phone: '收件电话',
      address: '收件地址',
      postalCode: '收件邮编'
    },
    filterForm: {
      goodsKeywords: '厂编/SKU/SPU',
      purchaseSnKeywords: '采购单/渠道单号',
      defectKeywords: '瑕疵点'
    },
    btns: {
      agree: '同意',
      refuse: '拒绝',
      payment: '支付',
      confirmReceipt: '确认签收',
      download: '导出明细'
    },
    table: {
      pic: '图片',
      size: '变体信息',
      returnNum: '退供数量',
      purchasePrice: '采购单价（¥）',
      returnPrice: '退供金额（¥）',
      defect: '瑕疵点',
      code: '唯一码',
      orderSn: '采购/渠道单号',
      returnReason: '申请类型',
      returnReasonTypeName: '退供原因',
      operate: '操作'
    },
    tableList: {
      compilation: '厂编',
      attribute: '属性',
      returnable: '应退',
      returnablePrice: '应退',
      retreat: '实退',
      needToReturn: '需退',
      showDetail: '查看详情',
      purchase: '采购',
      channel: '渠道'
    },
    modalTit: {
      agreeTit: '同意退回确认',
      agreeDesc: '请确认是否同意本次退货申请？',
      confirmReceiptTit: '签收确认',
      confirmReceiptDesc: '请确认是否已收到货物，确认则签收成功！',
      refuseTit: '拒绝原因',
      refusePlaceholder: '请输入拒绝原因',
      qualityTit: '质检详情',
      uniqueCode: '唯一码',
      defect: '瑕疵点',
      defectRemark: '质检备注'
    }
  },
  goodsManage: {
    filterForm: {
      nameSin: 'SKU/SPU/款式',
      productName: '商品名称'
    },
    tabsPane: {
      1: '上架中',
      2: '待上架',
      3: '已下架',
      4: '全部',
      5: '已归档'
    },
    table: {
      pic: '图片',
      spu: 'SPU',
      colorName: '颜色',
      sizes: '尺码',
      price: '采购价',
      storage: '商家库存',
      hh: '上架时间',
      createAt: '创建时间',
      ll: '下架时间',
      productName: '商品名称',
      // jj: '库存',
      // kk: '推送时间',
      state: '状态',
      operate: '操作',
      log: '日志',
      styleCode: '款式'
    }
  },
  paymentDeductBill: {
    filterForm: {
      orderNumber: '补扣款单号',
      methods: '补扣款方式',
      associatedNumber: '关联单号',
      startTime: '开始时间',
      endTime: '结束时间',
      type: '扣款类型',
      classify: '补扣款分类',
      paymentType: '结算方式'
    },
    methodsList: {
      1: '按退供单',
      2: '按采购单',
      3: '按结算周期'
    },
    typeList: {
      1: '扣款',
      2: '补款'
    },
    classifyList: {
      1: '运费扣款',
      2: '运费补款',
      3: '其他扣款',
      4: '其他补款'
    },
    paymentTypes: {
      1: '现结',
      2: '账期'
    },
    tabsPane: {
      1: '待处理',
      2: '已处理',
      3: '已驳回'
    },
    table: {
      number: '补扣款单号',
      classify: '补扣款分类',
      paymentType: '结算方式',
      money: '补扣款金额',
      methods: '补扣款方式',
      originalType: '原单类型',
      associatedNumber: '关联单号',
      remark: '备注',
      accessories: '申请附件',
      voucher: '确认凭证',
      type: '补扣类型',
      operator: '操作人',
      applicationTime: '申请时间',
      confirmationCertificate: '确认凭证',
      completionTime: '完成时间',
      download: '导出退款单明细'
    }
  },
  route: {
    orderManagement: {
      orderManagement: '订单中心',
      orderManage: '订单列表',
      orderManageDetail: '订单详情',
      shippingDetails: '一键发货详情',
      deliveryManage: '发货列表',
      deliveryManageDetail: '发货详情',
      returnManage: '退货列表',
      returnManageDetail: '退货详情'
    },
    goodsManagement: {
      goodsManagement: '商品中心',
      goodsManage: '商品管理'
    },
    financialManagement: {
      financialManagement: '财务中心',
      paymentDeductBill: '补扣款单'
    }
  }
}
